.bachelor--main-questions {
	margin: 90px 0 0 0;
	padding: 0;
	li {
		list-style-type: none;
		padding-left: 70px;
		position: relative;
		min-height: 40px;
		margin-bottom: 20px;
		h2 {
			box-sizing: border-box;
			padding-right: 210px;
			line-height: 40px;
		}
		button {
			position: absolute;
			top: 50%;
			right: 0;
			transform: translate(0, -50%);
		}
	}
	&-icon {
		top: 5px;
		width: 44px;
		height: 38px;
		text-align: center;
		color: #fff;
		line-height: 38px;
		left: 0;
		font-weight: bold;
		font-size: 18px;
		position: absolute;
		background: url('../static/stats/100%.png') no-repeat center center;
		background-size: cover;
	}
	@media (max-width: 1300px) {
		text-align: center;
		margin: 40px 0;
		&-icon {
			left: 50%;
			top: 20px;
			transform: translate(-50%, 0);
		}
		li {
			padding: 70px 0 0 0;
			height: auto;
			button {
				position: relative;
				top: auto;
				left: auto;
				margin-top: 20px;
				transform: translate(0, 0);
			}
			h2 {
				line-height: initial;
				padding-right: 0;
			}
		}
	}
}
