.bachelor-404 {
	position: relative;
	width: 100%;
	height: auto;
	display: inline-block;
	text-align: center;
	button {
		margin-top: 40px;
	}
}
