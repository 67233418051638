/*---------- SPACING ----------*/

/* Padding Zero */

.p0 {
	padding: 0;
}

/* Padding Top */

.pt5 {
	padding-top: 5px;
}

.pt10 {
	padding-top: 10px;
}

.pt15 {
	padding-top: 15px;
}

.pt20 {
	padding-top: 20px;
}

.pt30 {
	padding-top: 30px;
}

.pt40 {
	padding-top: 40px;
}

.pt50 {
	padding-top: 50px;
}

.pt60 {
	padding-top: 60px;
}

.pt70 {
	padding-top: 70px;
}

.pt80 {
	padding-top: 80px;
}

.pt90 {
	padding-top: 90px;
}

.pt100 {
	padding-top: 100px;
}

.pt110 {
	padding-top: 110px;
}

.pt120 {
	padding-top: 120px;
}

.pt130 {
	padding-top: 130px;
}

.pt140 {
	padding-top: 140px;
}

/* Padding Bottom */

.pb0 {
	padding-bottom: 0px;
}

.pb5 {
	padding-bottom: 5px;
}

.pb10 {
	padding-bottom: 10px;
}

.pb15 {
	padding-bottom: 15px;
}

.pb20 {
	padding-bottom: 20px;
}

.pb30 {
	padding-bottom: 30px;
}

.pb40 {
	padding-bottom: 40px;
}

.pb50 {
	padding-bottom: 50px;
}

.pb60 {
	padding-bottom: 60px;
}

.pb70 {
	padding-bottom: 70px;
}

.pb80 {
	padding-bottom: 80px;
}

.pb90 {
	padding-bottom: 90px;
}

.pb100 {
	padding-bottom: 100px;
}

.pb110 {
	padding-bottom: 110px;
}

.pb120 {
	padding-bottom: 120px;
}

.pb130 {
	padding-bottom: 130px;
}

.pb140 {
	padding-bottom: 140px;
}

/* Padding Left */

.pl0 {
	padding-left: 0px;
}

.pl3 {
	padding-left: 3px;
}

.pl5 {
	padding-left: 5px;
}

.pl10 {
	padding-left: 10px;
}

.pl20 {
	padding-left: 20px;
}

.pl30 {
	padding-left: 30px;
}

.pl40 {
	padding-left: 40px;
}

.pl50 {
	padding-left: 50px;
}

.pl60 {
	padding-left: 60px;
}

.pl70 {
	padding-left: 70px;
}

.pl80 {
	padding-left: 80px;
}

.pl90 {
	padding-left: 90px;
}

.pl100 {
	padding-left: 100px;
}

/* Padding Right */

.pr0 {
	padding-right: 0px;
}

.pr5 {
	padding-right: 5px;
}

.pr10 {
	padding-right: 10px;
}

.pr20 {
	padding-right: 20px;
}

.pr30 {
	padding-right: 30px;
}

.pr40 {
	padding-right: 40px;
}

.pr50 {
	padding-right: 50px;
}

.pr60 {
	padding-right: 60px;
}

.pr70 {
	padding-right: 70px;
}

.pr80 {
	padding-right: 80px;
}

.pr90 {
	padding-right: 90px;
}

.pr100 {
	padding-right: 100px;
}

.pr110 {
	padding-right: 110px;
}

.pr120 {
	padding-right: 120px;
}

@media (min-width: 1200px) {
	.pl-lg-50 {
		padding-left: 50px;
	}
	.pl-lg-80 {
		padding-left: 80px;
	}
}

@media (min-width: 992px) {
	.pt-md-50 {
		padding-top: 50px;
	}
}

/* Margin Auto */

.mr-auto {
	margin: auto;
	float: none;
}

/* Margin Top */

.mt0 {
	margin-top: 0;
}

.mt5 {
	margin-top: 5px;
}

.mt10 {
	margin-top: 10px;
}

.mt20 {
	margin-top: 20px;
}

.mt25 {
	margin-top: 25px;
}

.mt30 {
	margin-top: 30px;
}

.mt40 {
	margin-top: 40px;
}

.mt50 {
	margin-top: 50px;
}

.mt60 {
	margin-top: 60px;
}

.mt70 {
	margin-top: 70px;
}

.mt80 {
	margin-top: 80px;
}

.mt90 {
	margin-top: 90px;
}

.mt100 {
	margin-top: 100px;
}

.mt110 {
	margin-top: 110px;
}

.mt120 {
	margin-top: 120px;
}

/* Margin Bottom */

.mb0 {
	margin-bottom: 0;
}

.mb5 {
	margin-bottom: 5px;
}

.mb10 {
	margin-bottom: 10px;
}

.mb15 {
	margin-bottom: 15px;
}

.mb20 {
	margin-bottom: 20px;
}

.mb25 {
	margin-bottom: 25px;
}

.mb30 {
	margin-bottom: 30px;
}

.mb40 {
	margin-bottom: 40px;
}

.mb50 {
	margin-bottom: 50px;
}

.mb60 {
	margin-bottom: 60px;
}

.mb70 {
	margin-bottom: 70px;
}

.mb80 {
	margin-bottom: 80px;
}

.mb90 {
	margin-bottom: 90px;
}

.mb100 {
	margin-bottom: 100px;
}

.mb110 {
	margin-bottom: 110px;
}

.mb120 {
	margin-bottom: 120px;
}

/* Margin Left */

.ml0 {
	margin-left: 0;
}

.ml15 {
	margin-left: 5px;
}

.ml10 {
	margin-left: 10px;
}

.ml20 {
	margin-left: 20px;
}

.ml30 {
	margin-left: 30px;
}

.ml40 {
	margin-left: 40px;
}

.ml50 {
	margin-left: 50px;
}

.ml60 {
	margin-left: 60px;
}

.ml70 {
	margin-left: 70px;
}

.ml80 {
	margin-left: 80px;
}

.ml90 {
	margin-left: 90px;
}

.ml100 {
	margin-left: 100px;
}

.ml110 {
	margin-left: 110px;
}

/* Margin Right */

.mr0 {
	margin-right: 0;
}

.mr5 {
	margin-left: 5px;
}

.mr10 {
	margin-right: 10px;
}

.mr20 {
	margin-right: 20px;
}

.mr30 {
	margin-right: 30px;
}

.mr40 {
	margin-right: 40px;
}

.mr50 {
	margin-right: 50px;
}

.mr60 {
	margin-right: 60px;
}

.mr70 {
	margin-right: 70px;
}

.mr80 {
	margin-right: 80px;
}

.mr90 {
	margin-right: 90px;
}

.mr100 {
	margin-right: 100px;
}

.mr110 {
	margin-right: 110px;
}

.hidexl {
	@media (min-width: 1200px) {
		display: none !important;
	}
}

.hidelg {
	@media (min-width: 992px) and (max-width: 1200px) {
		display: none !important;
	}
}

.hidemd {
	@media (min-width: 768px) and (max-width: 992px) {
		display: none !important;
	}
}

.hidesm {
	@media (min-width: 576px) and (max-width: 768px) {
		display: none !important;
	}
}

.hidexs {
	@media (max-width: 576px) {
		display: none !important;
	}
}

.hidelg-down {
	@media (max-width: 1200px) {
		display: none !important;
	}
}

.hidemd-down {
	@media (max-width: 992px) {
		display: none !important;
	}
}

.hidesm-down {
	@media (max-width: 768px) {
		display: none !important;
	}
}

.hidelg-up {
	@media (min-width: 992px) {
		display: none !important;
	}
}

.hidemd-up {
	@media (min-width: 768px) {
		display: none !important;
	}
}

.hidesm-up {
	@media (min-width: 576px) {
		display: none !important;
	}
}
