.terms-modal {
	position: fixed;
	width: 100%;
	height: 100%;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background: rgba(0, 0, 0, 0.8);
	z-index: 9;
	&-close {
		width: 32px;
		height: 32px;
		border-radius: 32px;
		position: absolute;
		top: -40px;
		right: -40px;
		cursor: pointer;
		background-color: var(--secondary-color);
		color: #fff;
		@media (max-width: 400px) {
			top: -30px;
			right: -30px;
		}
		svg {
			font-size: 20px;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}
	}
	&-inner {
		min-height: 300px;
		max-height: 90vh;
		box-shadow: var(--shadow);
		width: 100%;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		background: var(--background-secondary);
		border-radius: 20px;
		height: auto;
		max-width: 900px;
		box-sizing: border-box;
		border: 30px solid var(--background-secondary);
		text-align: center;
		scrollbar-width: none;
		&::-webkit-scrollbar {
			width: 0px;
		}
		&::-webkit-scrollbar-track-piece {
			background-color: transparent;
			-webkit-border-radius: 6px;
		}
		&-buttons {
			margin-top: 90px;
			a {
				display: inline;
			}
			button {
				display: inline-block;
				margin: 0 10px 10px 10px;
			}
		}
		@media (max-width: 920px) {
			max-width: 90vw;
		}
		&-content {
			height: auto;
			overflow: auto;
			max-height: 350px;
			text-align: left;
			scrollbar-width: none;
			@media (max-width: 640px) {
				max-height: 300px;
			}
			&::-webkit-scrollbar {
				width: 0px;
			}
			&::-webkit-scrollbar-track-piece {
				background-color: transparent;
				-webkit-border-radius: 6px;
			}
		}
	}
}
