:root {
	--container-sm: 540px;
	--container-md: 720px;
	--container-lg: 960px;
	--container-xl: 1280px;
}

body {
	margin: 0;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

*,
*:before,
*:after {
	box-sizing: border-box;
}

img,
svg {
	max-width: 100%;
	display: inline-block;
}

@import './bootstrap.scss';
@import './animate.scss';
@import './helpers.scss';
@import './slick.scss';
