.bachelor-question-modal {
	position: fixed;
	width: 100%;
	height: 100%;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background: rgba(0, 0, 0, 0.8);
	z-index: 9;
	&-loading {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		z-index: 1;
		img {
			width: 200px;
		}
	}
	&-content {
		background: var(--background-secondary);
		position: relative;
		z-index: 2;
		&-stats {
			margin-top: 90px;
			position: relative;
			width: 100%;
			height: auto;
			white-space: nowrap;
			&:before {
				content: '';
				height: 10px;
				width: 100%;
				border-radius: 20px;
				background-color: #ffffff;
				position: absolute;
				top: 50%;
				left: 0;
				transform: translate(0, -200%);
			}
			&-stat {
				width: 20%;
				display: inline-block;
				position: relative;
				&:before {
					content: '';
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -120%);
					width: 22px;
					height: 22px;
					border-radius: 22px;
					background-color: #fff;
				}
				&-contestant {
					position: relative;
					img {
						max-width: 55%;
					}
				}
				&-value {
					position: relative;
					margin-bottom: 60px;
					img {
						max-width: 70%;
					}
					span {
						position: absolute;
						top: 50%;
						left: 50%;
						transform: translate(-50%, -70%);
						color: #ffffff;
						font-size: 25px;
						font-weight: bold;
					}
				}
			}
			@media (max-width: 640px) {
				margin-top: 30px;
				&:before {
					height: 100%;
					width: 10px;
					top: 0;
					left: 50%;
					transform: translate(-50%, 0);
				}
				&-stat {
					display: block;
					width: 100%;
					margin-bottom: 20px;
					&-value {
						width: 50%;
						display: inline-block;
						vertical-align: middle;
						margin: 0;
						span {
							font-size: 15px;
						}
						img {
							max-width: 50%;
						}
					}
					&-contestant {
						width: 50%;
						display: inline-block;
						vertical-align: middle;
						img {
							max-width: 40%;
							// display: inline-block;
							vertical-align: middle;
							// margin-right: 20px;
						}
						h2 {
							// display: inline-block;
							vertical-align: middle;
						}
					}
				}
			}
		}
	}
	&-inner {
		min-height: 780px;
		max-height: 90vh;
		overflow: auto;
		box-shadow: var(--shadow);
		width: 100%;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		background: var(--background-secondary);
		border-radius: 20px;
		height: auto;
		max-width: 900px;
		box-sizing: border-box;
		border: 20px solid var(--background-secondary);
		padding: 50px 30px 30px 30px;
		text-align: center;
		scrollbar-width: none;
		@media (max-width: 640px) {
			padding: 20px 10px 20px 10px;
		}
		&::-webkit-scrollbar {
			width: 0px;
		}
		&::-webkit-scrollbar-track-piece {
			background-color: transparent;
			-webkit-border-radius: 6px;
		}
		&-buttons {
			margin-top: 90px;
			@media (max-width: 640px) {
				margin-top: 30px;
			}
			a {
				display: inline;
			}
			button {
				display: inline-block;
				margin: 0 10px 10px 10px;
			}
		}
		@media (max-width: 920px) {
			max-width: 90vw;
		}

		@media (max-width: 640px) {
			min-height: 816px;
		}
	}
}
