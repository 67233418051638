:root {
	--background-primary: #0e1318;
	--background-secondary: #25282b;
	--background-tertiary: #393a3b;
	--container-background-primary: #e5e5e5;
	--primary-color: #d0c4a2;
	--secondary-color: #b20202;
	--secondary-color-hover: #800404;
	--tertiary-color: #25282b;
	--text-color: var(--primary-color);
	--text-color-alternative: #fff;
	--shadow: 0px 0px 15px rgba(0, 0, 0, 0.95);
	--container-xl: 1630px;
	--font-size: 16px;
	// --container-lg: 200px;
	min-height: 100vh;
	background-color: var(--background-primary);
	color: var(--text-color);
	font-family: Arial, Helvetica, sans-serif;
	font-size: 16px;
	body {
		box-sizing: border-box;
		padding: 0 0 80px 0;
	}
}

.error {
	text-align: center;
	width: 100%;
	font-weight: bold;
	font-size: 14px;
	color: var(--secondary-color);
	&.alt {
		color: #fff;
	}
}

a {
	color: var(--secondary-color);
	text-decoration: none;
}

p {
	margin: 0;
}

h1 {
	color: var(--text-color);
	font-size: 25px;
	margin: 0;
}

h2 {
	color: var(--text-color);
	font-size: 18px;
	margin: 0;
}

hr {
	border-bottom: 1px solid var(--primary-color);
	margin: 20px 0;
}

.bachelor {
	overflow: hidden;
	&--poll-results {
		background-color: var(--background-tertiary);
		border-radius: 20px;
		box-sizing: border-box;
		padding: 30px 20px 20px 20px;
		@media (max-width: 1200px) {
			border-radius: 0px;
		}
		h1 {
			font-weight: normal;
		}
		&-result {
			background: var(--background-secondary);
			margin-bottom: 2px;
			display: flex;
			flex-wrap: nowrap;
			position: relative;
			padding-left: 2px;
			@media (max-width: 575px) {
				flex-wrap: wrap;
				margin-bottom: 5px;
			}
			&-image {
				line-height: 0;
				max-width: 60px;
				width: 100%;
				position: relative;
				img {
					max-width: 50px;
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
				}
			}
			&-content {
				position: relative;
				flex-grow: 1;
				flex-basis: 0;
				padding: 15px 5%;
				&-question {
					font-size: 12px;
					line-height: 14px;
				}
				&-name {
					font-size: 18px;
					line-height: 19px;
					font-weight: bold;
					padding-top: 9px;
					@media (max-width: 1400px) {
						font-size: 16px;
						line-height: 17px;
					}
					@media (max-width: 370px) {
						font-size: 14px;
						line-height: 15px;
					}
				}
			}
			&-stats {
				display: inline-block;
				font-weight: bold;
				font-size: 16px;
				line-height: 16px;
				color: var(--tertiary-color);
				background-color: var(--primary-color);
				text-align: center;
				width: 100%;
				max-width: 70px;
				position: relative;
				float: right;
				&-value {
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
				}
				@media (max-width: 575px) {
					float: none;
					height: 31px;
					position: absolute;
					right: 0;
					bottom: 0;
					max-width: 60px;
				}
				@media (max-width: 370px) {
					font-size: 13px;
					line-height: 13px;
					height: 28px;
					max-width: 45px;
				}
			}
		}
	}
	&--selection-buttons {
		@media (max-width: 640px) {
			margin-top: 40px;
		}
	}
	&--selection-container {
		position: relative;
	}
	&--selection-fade {
		display: none;
		background: linear-gradient(to bottom, rgba(37, 40, 43, 0) 0%, var(--background-secondary) 100%);
		content: '';
		position: absolute;
		width: 100%;
		height: 130px;
		bottom: 0;
		left: 0;
		z-index: 3;
		margin-bottom: -10px;
		pointer-events: none;
		@media (max-width: 575px) {
			display: block;
		}
	}
	.bachelor--button-container {
		margin-top: 10px;
	}
	&--selection {
		margin-bottom: 50px;
		@media (max-width: 575px) {
			margin-bottom: 0;
			max-height: 390px;
			overflow: scroll;
			position: relative;
			padding-bottom: 140px;
			&::-webkit-scrollbar {
				width: 0px;
				background: transparent;
			}
		}
		&-contestants {
			margin: 0 5px 10px 5px;
			position: relative;
			white-space: nowrap;
			height: 70px;
			overflow: hidden;
			border-radius: 10px;
			cursor: pointer;
			transition: all 0.2s ease-in-out;
			-webkit-tap-highlight-color: rgba(255, 255, 255, 0);
			&-image {
				width: 100%;
				max-width: 60px;
				height: 100%;
				z-index: 2;
				position: relative;
				display: inline-block;
				img {
					position: absolute;
					bottom: 0;
					vertical-align: bottom;
				}
			}
			&-content {
				display: inline-block;
				z-index: 2;
				max-width: 78%;
				position: relative;
				color: var(--tertiary-color);
				vertical-align: top;
				padding: 25px 0px 0px 15px;
				transition: all 0.2s ease-in-out;
				@media (max-width: 1320px) {
					padding: 28px 0px 0px 10px;
				}
				@media (max-width: 575px) {
					color: var(--text-color-alternative);
				}
				&-name {
					font-weight: bold;
					font-size: 18px;
					line-height: 19px;
					@media (max-width: 1320px) {
						font-size: 15px;
						line-height: 16px;
					}
				}
				&-title {
					font-size: 15px;
					line-height: 18px;
					white-space: nowrap;
					text-overflow: ellipsis;
					max-width: 100%;
					overflow: hidden;
					display: inline-block;
					@media (max-width: 1320px) {
						font-size: 12px;
						line-height: 15px;
					}
				}
			}
			&-container {
				background: var(--container-background-primary);
				border-radius: 10px;
				height: 56px;
				width: 100%;
				position: absolute;
				bottom: 0;
				z-index: 1;
				transition: all 0.2s ease-in-out;
				border: 2px solid var(--container-background-primary);
				@media (max-width: 575px) {
					background: transparent;
					border: none;
					border-radius: 0px;
				}
			}
			&.disabled {
				pointer-events: none;
				filter: grayscale(100);
				opacity: 0.8;
			}
			&:hover & {
				&-container {
					border: 2px solid var(--secondary-color);
					@media (max-width: 575px) {
						border: none;
					}
				}
			}
			&.active & {
				&-content {
					color: var(--text-color-alternative);
					@media (max-width: 575px) {
						color: var(--secondary-color);
					}
				}
				&-container {
					background: var(--secondary-color);
					border: 2px solid var(--secondary-color);
					@media (max-width: 575px) {
						border: none;
						background: transparent;
					}
				}
			}
			@media (max-width: 575px) {
				border-bottom: 1px solid var(--primary-color);
				border-radius: 0px;

				&.active {
					border-bottom: 1px solid var(--secondary-color);
				}
			}
		}
	}
	&--contestants {
		position: relative;
		width: 100%;
		height: auto;
		background: var(--background-tertiary);
		box-sizing: border-box;
		padding: 40px 80px 115px 80px;
		box-shadow: var(--shadow);
		text-align: center;
		@media (min-width: 1433px) {
			min-height: 464px;
			padding: 40px 10% 115px 10%;
		}
		@media (min-width: 1200px) and (max-width: 1432px) {
			min-height: 588px;
		}
		@media (min-width: 992px) and (max-width: 1199px) {
			min-height: 364px;
		}
		@media (min-width: 1px) and (max-width: 991px) {
			min-height: 408px;
		}
		&-arrow {
			position: absolute;
			bottom: -30px;
			z-index: 1;
			cursor: pointer;
			font-size: 25px;
			&.next {
				right: 15px;
			}
			&.prev {
				left: 15px;
			}
		}
		@media (max-width: 991px) {
			padding: 40px 0 115px 0;
			h1 {
				padding: 0 40px;
			}
		}
		&-overlay {
			width: 100%;
			height: 100%;
			position: fixed;
			top: 50%;
			z-index: 9;
			left: 50%;
			transform: translate(-50%, -50%);
			background: rgba(0, 0, 0, 0.8);
		}
		&-modal {
			position: fixed;
			top: 130px;
			left: 50%;
			transform: translate(-50%, 0%);
			width: 100%;
			max-width: 330px;
			height: auto;
			max-height: 90vh;
			background: var(--background-secondary);
			box-shadow: var(--shadow);
			box-sizing: border-box;
			padding: 30px 15px 50px 15px;
			border-radius: 20px;
			z-index: 9;
			text-align: center;
			img {
				max-width: 130px;
				margin-top: -50px;
			}
			&-arrow {
				position: absolute;
				bottom: 10px;
				z-index: 1;
				cursor: pointer;
				font-size: 25px;
				&.prev {
					left: 15px;
				}
				&.next {
					right: 15px;
				}
			}
			&-content {
				max-height: 350px;
				height: auto;
				overflow: auto;
				margin: 10px 0;
				scrollbar-width: none;
				&::-webkit-scrollbar {
					width: 0px;
				}
				&::-webkit-scrollbar-track-piece {
					background-color: transparent;
					-webkit-border-radius: 6px;
				}
				p {
					margin: 0;
				}
				@media (max-height: 500px) {
					height: 160px;
				}
				@media (max-height: 400px) {
					height: 100px;
				}
			}
			&-close {
				width: 32px;
				height: 32px;
				border-radius: 32px;
				position: absolute;
				top: -10px;
				right: -10px;
				cursor: pointer;
				background-color: var(--secondary-color);
				color: #fff;
				@media (max-width: 400px) {
					top: 0;
					right: 0;
				}
				svg {
					font-size: 20px;
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
				}
			}
		}
		&-contestant {
			position: relative;
			cursor: pointer;
			width: 100%;
			padding: 0 8px 8px 8px;
			height: auto;
			outline: none;
			will-change: transform;
			transition: all 0.2s ease-in-out;
			&.active {
				animation-name: pulsate;
				animation-iteration-count: 1;
				animation-duration: 1.2s;
				animation-fill-mode: both;
			}
			img {
				width: 100%;
				transition: all 0.2s ease-in-out;
				height: auto;
			}
			@media (min-width: 1200px) {
				width: 113px;
				display: inline-block;
			}
			&:hover {
				@media (min-width: 640px) {
					img {
						transform: scale(1.1);
					}
				}
			}
		}
	}
	&--button-container {
		&.centered {
			text-align: center;
		}
		&-button {
			font-size: 18px;
			line-height: 18px;
			font-weight: bold;
			text-transform: uppercase;
			color: var(--text-color-alternative);
			transition: all 0.2s ease-in-out;
			background: var(--secondary-color);
			box-sizing: border-box;
			border: 2px solid var(--secondary-color);
			border-radius: 22.5px;
			padding: 10px 10px 9px 10px;
			text-align: center;
			width: 100%;
			max-width: 334px;
			outline: none;
			cursor: pointer;
			&.outlined {
				border: 2px solid var(--secondary-color);
				background-color: transparent;
			}
			&.small {
				max-width: 200px;
			}
			&.disabled {
				pointer-events: none;
				opacity: 0.6;
			}
			&.loading {
				pointer-events: none;
				color: transparent !important;
				position: relative;
				&:after {
					content: '';
					width: 40px;
					height: 40px;
					background: url('../static/loading.gif') no-repeat center center;
					background-size: cover;
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
				}
			}
			&:hover {
				background: var(--secondary-color-hover);
				border: 2px solid var(--secondary-color-hover);
			}
		}
	}
	&--main {
		position: relative;
		overflow: hidden;
		background: var(--background-secondary);
		width: calc(100% - 80px);
		margin: -80px auto 0 auto;
		box-sizing: border-box;
		padding: 20px;
		border-radius: 20px;
		min-height: 500px;
		@media (max-width: 991px) {
			width: calc(100% - 20px);
		}
		&-logout {
			display: block;
			position: absolute;
			left: -5px;
			top: 27px;
			z-index: 1;
			cursor: pointer;
			padding: 0 40px;
			line-height: 53px;
			height: 53px;
			font-weight: bold;
			border-radius: 0 10px 10px 0;
			color: #fff;
			background: var(--secondary-color);
		}
		&-content {
			padding-top: 20px;
			padding-bottom: 20px;
			position: relative;
		}
		&-form {
			display: flex;
			align-items: center;
			height: 100%;
			box-sizing: border-box;
			padding: 90px 0 40px 0;
			&-inner {
				width: 100%;
			}
			.input-wrapper-text {
				display: inline-block;
				@media (max-width: 992px) {
					display: block;
					margin-bottom: 10px;
				}
			}
			.input-wrapper-checkbox {
				margin-top: 10px;
				position: relative;
				margin-bottom: 20px;
				label {
					position: relative;
					box-sizing: border-box;
					padding-left: 28px;
					&:before {
						content: '';
						position: absolute;
						top: 0;
						left: 0;
						width: 19px;
						border-radius: 19px;
						height: 19px;
						background-color: var(--primary-color);
						cursor: pointer;
					}
					&:after {
						content: '';
						width: 15px;
						height: 15px;
						border-radius: 15px;
						background-color: var(--secondary-color);
						position: absolute;
						display: none;
						top: 2px;
						cursor: pointer;
						left: 2px;
					}
				}
				input {
					display: none;
					&:checked ~ label {
						&:after {
							display: block;
						}
					}
				}
				&.inline {
					display: inline-block;
					margin: 0 10px;
				}
			}
			.input-wrapper {
				position: relative;
				margin-bottom: 30px;
				&-error {
					margin-top: 10px;
					text-align: center;
					font-size: 12px;
				}
				input {
					width: 100%;
					background: transparent;
					color: var(--primary-color);
					font-size: 16px;
					box-sizing: border-box;
					padding: 0 20px;
					border: 1px solid var(--primary-color);
					outline: none;
					border-radius: 20px;
					height: 36px;
					line-height: 36px;
					&::placeholder {
						color: var(--primary-color);
					}
				}
			}
		}
		&.internal {
			.bachelor--main-content {
				padding-top: 80px;
			}
		}
	}
}

.terms {
	color: var(--secondary-color);
	display: inline-block;
	cursor: pointer;
}

@keyframes pulsate {
	0% {
		transform: scale(1);
	}
	50% {
		transform: scale(1.1);
	}
	100% {
		transform: scale(1);
	}
}
