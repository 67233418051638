.bachelor--error {
	background-color: var(--primary-color);
	position: absolute;
	z-index: 9;
	top: 100px;
	left: -100%;
	max-width: 100%;
	width: 320px;
	min-height: 50px;
	height: auto;
	box-shadow: 0px 5px 3px rgba(0, 0, 0, 0.45);
	box-sizing: border-box;
	padding: 15px 15px 15px 60px;
	color: #000;
	z-index: -1;
	cursor: pointer;
	opacity: 0;
	transition: all 0.5s ease-in-out;
	&.active {
		left: 0;
		opacity: 1;
		z-index: 1;
	}
	&.loginOpen {
		@media (min-width: 640px) {
			top: 230px;
		}
	}
	@media (max-width: 1200px) {
		position: fixed;
		bottom: -100%;
		left: 50%;
		top: auto;
		top: auto;
		transform: translate(-50%, 0);
		&.active {
			top: auto;
			left: 50%;
			bottom: 0;
		}
	}
	img {
		position: absolute;
		top: 50%;
		left: 10px;
		transform: translate(0, -50%);
	}
	&-timer {
		width: 100%;
		height: 5px;
		position: absolute;
		bottom: 0;
		left: 0;
		background-color: var(--secondary-color);
		transition: all 0.12s ease-in-out;
	}
	&:before {
		content: '';
		width: 100%;
		height: 5px;
		position: absolute;
		bottom: 0;
		left: 0;
		background-color: #b0a687;
	}
	&-error {
		margin-bottom: 10px;
		&:last-child {
			margin-bottom: 0;
		}
	}
}
