.bachelor--login {
	position: absolute;
	left: -5px;
	display: block;
	top: 7px;
	cursor: pointer;
	padding: 0 40px;
	line-height: 53px;
	height: 53px;
	font-weight: bold;
	border-radius: 0 10px 10px 0;
	color: #fff;
	background: var(--secondary-color);
	transition: all 0.5s ease-in-out;
	&.active {
		left: -100%;
	}
	&--container {
		position: absolute;
		left: -100%;
		top: 7px;
		color: #fff;
		background: var(--secondary-color);
		border-radius: 0 10px 10px 0;
		box-sizing: border-box;
		padding: 30px 20px 30px 20px;
		width: 320px;
		z-index: 9;
		max-width: 90%;
		min-height: 78px;
		transition: all 0.3s ease-in-out 0s;
		&.errors {
			@media (max-width: 1600px) and (min-width: 1200px) {
				top: 45px !important;
			}
		}
		&--exit {
			cursor: pointer;
			position: absolute;
			width: 20px;
			height: 20px;
			top: 5px;
			right: 5px;
			svg {
				font-size: 20px;
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
			}
		}
		.input-wrapper-error {
			margin-top: 0;
			margin-bottom: 5px;
		}
		&-resend {
			font-size: 14px;
			line-height: 42px;
			strong {
				cursor: pointer;
			}
			@media (max-width: 370px) {
				width: 300px;
			}
		}
		button {
			color: var(--secondary-color);
			background-color: #fff;
			width: auto;
			padding: 10px 15px;
			&.loading {
				background-color: #7f0202;
			}
			&:hover {
				color: #fff;
			}
		}
		input {
			background-color: #7f0202;
			border-radius: 50px;
			border: 0;
			height: 30px;
			box-sizing: border-box;
			padding: 0 15px;
			line-height: 30px;
			outline: none;
			margin-bottom: 10px;
			color: rgba(255, 255, 255, 0.8);
			font-size: var(--font-size);
			width: 100%;
			&::placeholder {
				color: rgba(255, 255, 255, 0.8);
			}
		}
		&.active {
			left: -5px;
			transition: all 0.3s ease-in-out 0.4s;
		}
	}
	// &--login,
	// &--resend {
	// 	position: absolute;
	// 	left: -100%;
	// 	top: 7px;
	// 	color: #fff;
	// 	background: var(--secondary-color);
	// 	border-radius: 0 10px 10px 0;
	// 	box-sizing: border-box;
	// 	padding: 13px 20px;
	// 	width: 300px;
	// 	height: 78px;
	// 	transition: all 0.3s ease-in-out 0.4s;
	// 	&.active {
	// 		left: -5px;
	// 	}
	// }
}
